body {
  background-color: #ffffff;
}

.login-container {
  background-color: #fff;
}

.bienvenido {
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  font-size: 33px; /* Reduced by 2px */
  margin-bottom: 40px;
  font-weight: bold;
  margin: 27px;
}

.cuadros-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.cuadro {
  width: 460px;
  height: 250px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.cuadro h2 {
  font-size: 22px; /* Reduced by 2px */
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

.cuadro p {
  font-size: 18px; /* Reduced by 2px */
  margin-bottom: 20px;
  font-weight: lighter;
  text-align: center;
}

.cuadro button {
  width: 100%;
}

.btnAdmin {
  background-color: #ffdf1b; /* Preferred background color */
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  opacity: .90;
}

.btnAdmin:hover {
  background-color: #fff885;
  border: #fff885;
}

/* Media Queries for Responsiveness */

/* For tablets and small screens */
@media (max-width: 768px) {
  .cuadro {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .bienvenido {
    text-align: center;
  }
}

/* For mobile phones */
@media (max-width: 576px) {
  .cuadros-container {
    flex-direction: column;
    gap: 20px;
  }
  
  .cuadro {
    padding: 15px;
  }
  
  .bienvenido {
    font-size: 26px; /* Reduced by 2px */
  }
  
  .cuadro h2 {
    font-size: 18px; /* Reduced by 2px */
  }
  
  .cuadro p {
    font-size: 16px; /* Reduced by 2px */
  }
}
