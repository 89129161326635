.ContenedorCuadro {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.programa {
  width: 45%;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
}

.programa1 {
  width: 40%;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
}

.SubtituloP {
  font-family: 'Open Sans', sans-serif;
  font-size: 30px;
  font-weight: bolder;
  margin-bottom: 20px;
}

.evento {
  display: flex;
  margin-bottom: 20px;
}

.eventoImagen {
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
}

.eventoDetalles {
  flex: 1;
}

.eventoTitulo {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  font-weight: bolder;
  text-align: left;
}

.eventoFecha {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: lighter;
  text-align: left;
}

.eventoBotones {
  display: flex;
  gap: 10px;
}

.nuevoEvento {
  width: 100%;
  margin-top: 20px;
  background-color: #ffdf1b;
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}
.nuevoEvento:hover {
  background-color: #fff885; 
  border: #fff885;
}


.n{
  background-color:#fff885;
  
}

.recorrido {
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  margin-bottom: 10px;
}

.recorridoFecha {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  display: flex;
  align-items: center;
  margin-right: 10px; 
}

.recorridoDetalles {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between; 
}

.recorridoPersona {
  font-size: 16px;
  font-weight: bold;
  margin-left: 40px;
}

.IconoXD {
  margin-right: 5px;
}

.RecorridoIcono {
  margin-right: 10px; 
  font-size: 25px;
  align-items: center;
  justify-items: center;
}

.SubtituloNX {
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  text-align: left;
}

.margenver {
  margin-right: 15px; 
}

.color1 {
  background-color: #ffdf1b;
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.color1:hover {
  background-color: #fff885; 
  border: #fff885;
}


.color2 {
  background-color: #ff9966;
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.color2:hover {
  background-color: #ffc8a9; 
  border: #ffc8a9;
}


.modal-body input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.centrado{
  text-align: justify;
  justify-content: center;
}

.modal-body input[type="file"] {
  padding: 0;
}

.modal-body input::placeholder {
  color: #888;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.modal-footer .btn-secondary {
  background-color: #c1cfde;
}

.modal-footer .btn-primary {
  background-color: #c1cfde;
}

/* Estilos para el modal de edición */
.modal.fade.show {
  display: block;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  border-radius: 0.3rem;
}

.modal.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
