.AntesT {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 35px; /* Tamaño por defecto para pantallas grandes */
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 75px; /* Ajuste específico para pantallas grandes */
}

.Subtitulo {
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  font-weight: bold;
  font-size: 24px; /* Tamaño por defecto para pantallas grandes */
  margin-left: 3%; /* Ajuste específico para pantallas grandes */
  margin-top: 50px;
  margin-bottom: 60px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.image-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.image-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  box-sizing: border-box;
}

.image {
  width: 100%;
  max-width: 350px; /* Tamaño por defecto para pantallas grandes */
  height: auto;
  margin-bottom: 20px;
}

.image-text {
  margin-top: 20px;
  font-size: 20px; /* Tamaño por defecto para pantallas grandes */
  color: #333;
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  font-weight: lighter;
  margin-bottom: 20px;
}

.cross {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100px; /* Tamaño por defecto para pantallas grandes */
  height: 100px; /* Tamaño por defecto para pantallas grandes */
  margin: 0 20px;
}

.horizontal {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100px; /* Tamaño por defecto para pantallas grandes */
  height: 8px; /* Tamaño por defecto para pantallas grandes */
  background-color: #ffdf1b;
  transform: translateY(-50%);
}

.vertical {
  position: absolute;
  width: 8px; /* Tamaño por defecto para pantallas grandes */
  background-color: #ffdf1b;
  transform: translateX(-50%);
}

/* Estilos para pantallas móviles */
@media (max-width: 767px) {
  .AntesT {
    font-size: 28px; /* Reducir tamaño del texto en móviles */
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Subtitulo {
    font-size: 18px; /* Reducir tamaño del subtítulo en móviles */
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .image-container {
    margin: 10px; /* Reducir margen en móviles */
  }

  .image-row {
    flex-direction: column; /* Cambiar dirección a columna para móviles */
    align-items: stretch; /* Asegurar que las columnas ocupen el ancho completo */
  }

  .column {
    width: 100%; /* Asegurar que las columnas ocupen todo el ancho disponible */
    margin: 0; /* Eliminar márgenes laterales */
  }

  .image-with-text {
    margin: 10px 0; /* Reducir márgenes en móviles */
  }

  .image {
    max-width: 100%; /* Ajustar imagen al ancho completo del contenedor en móviles */
    height: auto; /* Mantener la proporción de la imagen */
  }

  .image-text {
    font-size: 14px; /* Reducir tamaño del texto en móviles */
    text-align: center; /* Centrar el texto */
    padding: 0 5px; /* Reducir padding */
  }

  .cross {
    width: 55px; /* Reducir tamaño del cruce en móviles */
    height: 50px;
    margin: 5px 0; /* Reducir margen */
  }

  .horizontal {
    width: 50px; /* Reducir tamaño del cruce horizontal */
    height: 5px; /* Reducir tamaño del cruce horizontal */
  }

  .vertical {
    width: 5px; /* Reducir tamaño del cruce vertical */
    height: 50px; /* Reducir tamaño del cruce vertical */
  }
}

/* Estilos para pantallas portátiles y de tamaño medio */
@media (min-width: 768px) and (max-width: 1023px) {
  .AntesT {
    font-size: 40px;
    margin-left: 100px;
  }

  .Subtitulo {
    font-size: 28px;
    margin-left: 5%;
  }

  .image-text {
    font-size: 18px;
    text-align: justify;
  }

  .image {
    max-width: 400px;
  }

  .cross {
    width: 110px;
    height: 100px;
    margin-top: 0;
  }

  .horizontal {
    width: 90px;
    height: 8px;
  }

  .vertical {
    width: 9px;
    height: 100px;
  }
}

/* Estilos para pantallas grandes */
@media (min-width: 1024px) {
  .AntesT {
    font-size: 45px;
  }

  .Subtitulo {
    font-size: 30px;
  }

  .image-text {
    font-size: 20px;
  }

  .image {
    max-width: 450px;
  }

  .cross {
    width: 120px;
    height: 120px;
  }

  .horizontal {
    width: 110px;
    height: 10px;
  }

  .vertical {
    width: 10px;
    height: 120px;
  }
}
