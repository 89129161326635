.cuadros-container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
} 

.cuadro1 {
  width: 460px;
  height: max-content;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cuadro1 h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

.cuadro1 p {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: lighter;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Button {
  flex-grow: 1;
  margin: 0 10px;
}

.fotoXD {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}

.placeholder1 {
  width: 80%;
  text-align: center;
}

.deleted {
  margin-left: 1270px;
}


.btnAD{
  background-color: #ffdf1b; 
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  opacity: .90;
  margin-bottom: 20px;
}

.btnAD:hover {
  background-color: #fff885; 
  border: #fff885;
}
.n{
  background-color:#fff885;
  
}

.btnEl {
  background-color: #ff9966;
  border-radius: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.color2:hover {
  background-color: #ffc8a9; 
  border: #ffc8a9;
}