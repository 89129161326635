.texto {
  font-family: 'Open Sans', sans-serif;
  margin-left: 140px;
  text-align: center;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 38px;
  margin-top: 20px;
}

.texto1 {
  font-family: 'Open Sans', sans-serif;
  margin-left: 110px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 115px;
  font-weight: lighter;
}

.imagen {
  float: right;
  margin-left: 90px;
  margin-right: 85px;
  margin-bottom: 20px;
  width: 30%;
}

.imagen1 {
  float: left;
  margin-left: 90px;
  margin-right: 85px;
  width: 30%;
}

.texto2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}

.texto3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  margin-right: 29px;
  text-align: center;
  margin-left: 120px;
  margin-bottom: 145px;
  font-weight: lighter;
}

.texto4 {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  text-align: center;
  margin-right: 300px;
  margin-left: 300px;
  font-weight: lighter;
}

.texto5 {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  text-align: center;
  color: black;
  font-weight: bold;
  margin-left: 340px;
}

.social-icons {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: 10%;
  margin-left: 58px;
  margin-bottom: 70px;
}

hr {
  height: 20px;
  background-color: #99b3c6;
  height: 15px;
  border: none;
}


/* Estilo para el botón */
#openModalBtn {
  font-size: 16px;
  width: 200px;
  height: 50px;
  margin-right: 300px;
  font-size: 23px;
  background: #fcd152;
  border: none;
}

.modal-body {
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  font-weight:lighter;

}

.Introduccion {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

/* Media queries para hacer el diseño responsivo */
@media (max-width: 1200px) {
  .texto {
    margin-left: 100px;
    font-size: 30px;
  }

  .texto1 {
    margin-left: 80px;
  }

  .imagen,
  .imagen1 {
    width: 40%;
    margin-left: 50px;
    margin-right: 50px;
  }

  .texto3 {
    margin-left: 90px;
    margin-right: 20px;
  }

  .texto4 {
    margin-right: 200px;
    margin-left: 200px;
  }

  .texto5 {
    margin-left: 250px;
    margin-right: 50px;
  }

  #openModalBtn {
    margin-right: 200px;
  }
}

@media (max-width: 768px) {

  .texto,
  .texto2 {
    margin-left: 0;
    font-size: 25px;
  }

  .texto1,
  .texto3,
  .texto4,
  .texto5 {
    margin-left: 0;
    margin-right: 0;
  }

  .imagen,
  .imagen1 {
    float: none;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .social-icons {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }

  #openModalBtn {
    margin-right: 0;
  }
}

@media (max-width: 480px) {

  .texto,
  .texto2 {
    font-size: 20px;
  }

  .texto1,
  .texto3,
  .texto4,
  .texto5 {
    font-size: 15px;
  }

  .imagen,
  .imagen1 {
    width: 100%;
  }

  #openModalBtn {
    width: 100%;
    height: auto;
  }
}