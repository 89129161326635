/* Archivo base CSS */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

/* Estilos para el header */
.header-bar {
  background-color: #ffc107;
  width: 99%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-bar img {
  height: 40px;
}

.btn {
  background-color: transparent;
  color: rgb(0, 0, 0);
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 999px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.palabra {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  text-align: center;
  color: black;
  font-weight: bold;
  justify-content: center;
}

.btn:hover {
  background-color: white;
  color: #2d3748;
}

.btn-outline {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 999px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-outline:hover {
  background-color: white;
  color: #2d3748;
}

/* Estilos para el carrusel */
.carousel {
  width: 100%;
  height: 450px;
  overflow: hidden;
  position: relative;
  display: flex;
}

.BootstrapCarousel{
  height: 450px;
  width: 90%;
}

.carousel-item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Ajustamos los botones de navegación del carrusel */
.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 2; /* Asegura que los botones estén por encima de las imágenes */
  height: 50px;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-prev {
  left: 20px;
}

.carousel-next {
  right: 20px;
}

/* Añadimos el margen inferior al contenedor del carrusel */
.content {
  margin-bottom: 40px;
  /* Ajusta este valor según sea necesario */
  border-bottom: 5px solid #ffc107;
  /* Añadimos la línea inferior */
}

/* Media queries para mejorar la responsividad */
@media (max-width: 768px) {
  .carousel {
    height: 200px; /* Ajusta la altura del carrusel en pantallas pequeñas */
  }
  
  @media (max-width: 768px) {
    .carousel {
      height: 300px; /* Ajusta la altura del carrusel para pantallas más pequeñas */
    }
  
    .content {
      margin-bottom: 20px; /* Reduce el margen inferior para pantallas más pequeñas */
    }
  }
}
  /* Media Queries para Pantallas Muy Pequeñas */
  @media (max-width: 480px) {
    .carousel {
      height: 200px; /* Ajusta la altura del carrusel para pantallas muy pequeñas */
    }
  
    .content {
      margin-bottom: 10px; /* Reduce aún más el margen inferior */
    }
  }
/* Estilos para la frase */
.quote {
  text-align: center;
  margin: 20px 0;
  font-size: 1.2rem;
  font-style: italic;
  color: #333;
  padding: 0 20px;
  margin-bottom: 30px;
  border-bottom: 5px solid #ffc107;
  margin-top: 30px;
}

/* Estilos para el parrafo */
p {
  text-align: right;
  margin-right: 20px;
  font-style: normal;
  font-weight: bold;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .content p {
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 10px 0;
  }
  
  .content h1, .content h2, .content h3 {
    font-size: 1.2rem;
    margin: 15px 0;
  }
  
  .content ul, .content ol {
    padding-left: 20px;
    margin: 10px 0;
  }

  .content {
    padding: 10px;
  }
  
  .content .title, .content .text {
    text-align: left;
    margin: 0;
    padding: 10px;
  }
}


/* Ajustes para pantallas medianas */
@media (min-width: 601px) and (max-width: 1024px) {
  .quote {
    font-size: 1.1rem;
    padding: 0 15px;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  p {
    margin-right: 15px;
    font-size: 1rem;
  }
}

/* Ajustes para pantallas grandes */
@media (min-width: 1025px) {
  .quote {
    font-size: 1.2rem;
    padding: 0 20px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  p {
    margin-right: 20px;
    font-size: 1.1rem;
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
  /* Ajusta este valor según sea necesario */
  margin-bottom: 20px;
  /* Añadimos margen inferior para separar de la frase */
}

.image-button {
  position: relative; /* Necesario para que el pseudo-elemento se posicione correctamente */
  background-size: cover;
  background-position: center;
  border: none;
  border-radius: 12px;
  padding: 20px 40px; /* Ajusta el padding según sea necesario */
  color: #ffc107;
  font-size: 1rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  width: 100%; /* Ancho del botón */
  height: 200px; /* Altura del botón */
  overflow: hidden; /* Asegura que la superposición no se desborde del botón */
}

.image-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Color de la superposición con opacidad */
  z-index: 1; /* Asegura que el pseudo-elemento esté por encima del fondo pero debajo del texto */
}

.image-button span {
  position: relative;
  z-index: 2; /* Asegura que el texto esté por encima del pseudo-elemento */
}

.image-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.671);
}

/* Estilos para la sección ¿Sabías que...? */
.sabias-que-container {
  margin-top: 40px;
  text-align: left;
}

.sabias-que-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.sabias-que-text {
  flex: 1;
  text-align: left;
  padding: 20px;
  background-color: #f7fafc;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sabias-que-image {
  flex: 1;
  text-align: right;
}

.sabias-que-image img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sabias-que-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.sabias-que-button {
  width: 70px;
  /* Ajusta el ancho del botón */
  height: 10px;
  /* Ajusta la altura del botón */
  margin: 0 10px;
  /* Espaciado entre botones */
  background-color: #9c9b99;
  /* Color de fondo */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  /* Tamaño de la fuente */
  color: white;
  transition: background-color 0.3s ease;
}

/* Estilos para la sección TCC Móvil */
.tcc-movil-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
  /* Ajusta este valor según sea necesario */
}

.tcc-movil-text {
  margin-right: 10%;
  text-align: left;
}

.tcc-movil-text h2 {
  font-size: 1.5rem;
  color: #333;
  margin-left: 40%;
}

.tcc-movil-text p {
  font-size: 1rem;
  color: #555;
  text-align: center;
}

.quedicen {
  text-align: center;
  margin-right: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin-top: 100px;
  
}

.comentarios-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  /* Permite que los elementos se distribuyan en varias líneas */
  margin: 20px;
}

.comentario {
  background-color: white;
  border: 2px solid #ffc107;
  border-radius: 10px;
  padding: 10px;
  /* Reducido el padding para hacer los cuadros más compactos */
  width: 250px;
  /* Ancho reducido para que los cuadros sean más pequeños */
  max-width: 100%;
  /* Ajustado para mantener la responsividad */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 190px;
}

.comentario-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.icono-tren {
  width: 22px;
  /* Ajusta el tamaño del ícono */
  height: 22px;
  /* Ajusta el tamaño del ícono */
  margin-right: 5px;
}

.comentario-usuario {
  font-weight: bold;
}

.comentario-fecha {
  color: gray;
  margin-bottom: 5px;
}

.comentario-texto {
  text-align: left;
}


.redes-sociales-container {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  /* Ajusta el margen superior según sea necesario */
  border-bottom: 5px solid #ffc107;
}

.redes-sociales-icon {
  font-size: 2.5rem;
  /* Tamaño del ícono ajustable */
  margin: 0 10px;
  /* Espacio entre los íconos, ajustable según sea necesario */
}

.hipervinculos-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  /* Espacio superior */
}

.hipervinculos-container a {
  margin: 0 10px;
  /* Espacio entre los hipervínculos */
  color: black;
  /* Color de texto negro */
  text-decoration: none;
  /* Sin subrayado */
}

.hipervinculos-container a:hover {
  text-decoration: underline;
  /* Subrayado al pasar el mouse */
}

/* App.css */
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
}

.cookie-banner p {
  margin: 0;
  font-size: 14px;
}

.cookie-banner button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.cookie-banner button:hover {
  background-color: #0056b3;
}

.password-container {
  position: relative;
}

.password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.btn-agenda {
  border: 0px;
  font-weight: bold;
  border-radius: 25px;
  font-size: 15px;
}

.btn-agenda:hover {
  background-color: #fff885;
}

/* Media Queries */

/* Dispositivos móviles */
@media (max-width: 768px) {
  .header-bar {
    flex-direction: column;
    align-items: flex-start;
  }


 
  .buttons-container {
    flex-direction: column;
  }

  .tcc-movil-container {
    flex-direction: column;
    align-items: center; /* Centra el contenido dentro del contenedor */
    margin-top: 20px; /* Ajusta el margen superior si es necesario */
  }

  .tcc-movil-text {
    margin-right: 0;
    text-align: center;
    margin-bottom: 10px; /* Añade margen inferior para separarlo del contenido siguiente */
  }

  .tcc-movil-text h2, .tcc-movil-text p {
    margin-left: 0;
  }

  .sabias-que-content {
    flex-direction: column;
  }

  .sabias-que-image {
    text-align: center;
  }

  .comentarios-container {
    flex-direction: column;
    align-items: center;
  }
}

/* Tabletas */
@media (min-width: 769px) and (max-width: 1024px) {
  .header-bar {
    flex-direction: column;
    align-items: flex-start;
  }

/*   .buttons-container {
    flex-direction: column;
  }
 */
  .tcc-movil-container {
    flex-direction: column;
  }

  .tcc-movil-text {
    margin-right: 0;
    text-align: center;
  }

  .tcc-movil-text h2, .tcc-movil-text p {
    margin-left: 0;
  }

  .sabias-que-content {
    flex-direction: column;
  }

  .sabias-que-image {
    text-align: center;
  }

  .comentarios-container {
    flex-direction: column;
    align-items: center;
  }
}

/* Pantallas de escritorio grandes */
@media (min-width: 1025px) {
  .buttons-container {
    flex-direction: row;
  }

  .tcc-movil-container {
    flex-direction: row;
    padding: auto;
  }

  .tcc-movil-text {
    margin-right: 10%;
    text-align: center;
  }

  .tcc-movil-text h2{
    margin-left: -19%;
  }
  .tcc-movil-text p {
    margin-right: 25%;
  }

  .sabias-que-content {
    flex-direction: row;
  }

  .sabias-que-image {
    text-align: right;
  }

  .comentarios-container {
    flex-direction: row;
    align-items: flex-start;
  }
}
