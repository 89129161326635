.titulo {
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  font-weight: bold;
  font-size: 35px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.social-icons {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: 10%;
  margin-left: 58px;
  margin-bottom: 70px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  padding: 20px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.content-wrapper {
  border: 2px solid #fff885;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  height: auto; 
  min-height: 450px;
}

.imag-container {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 15px;
}

.imag {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.text {
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  font-weight: lighter;
  font-size: 18px;
  margin: 10px 0;
  overflow-wrap: break-word;
}

/* Styles for larger screens */
@media (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Styles for tablets and small screens */
@media (max-width: 768px) {
  .titulo {
    font-size: 28px;
    text-align: center;
  }

  .grid-container {
    padding: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .text {
    font-size: 16px;
  }

  .content-wrapper {
    padding: 10px;
  }
}

/* Styles for mobile screens */
@media (max-width: 480px) {
  .grid-container {
    padding: 5px;
    grid-template-columns: 1fr;
  }

  .text {
    font-size: 14px;
    line-height: 1.4; 
  }

  .content-wrapper {
    padding: 5px;
    height: auto; 
    min-height: 530px; 
  }

  .text,
  .text a {
    display: inline-block;
    word-wrap: break-word;
    word-break: break-word;
  }

  .text a {
    color: #007bff; 
    text-decoration: underline; 
  }
}
