/* Carousel.css */

.carousel-container {
  position: relative;
  width: 100%; /* Asegura que el contenedor ocupe el 100% del ancho disponible */
  max-width: 1500px; /* Ajusta el tamaño máximo */
  height: 100%;
  margin: 0 auto; /* Centra el carrusel horizontalmente */
  overflow: hidden;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%; /* Ajusta el ancho de cada imagen */
  flex: 0 0 auto; /* Ajusta el comportamiento de crecimiento */
}

.carousel-item.active {
  display: block;
}

.carousel img {
  width: 100%; /* Ajusta el tamaño de la imagen */
  height: auto;
  object-fit: cover;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-prev {
  left: 10px;
}

.carousel-next {
  right: 10px;
}

/* Media queries para pantallas más pequeñas */
@media (max-width: 1200px) {
  .carousel-container {
    max-width: 1000px;
  }

  .carousel-btn {
    font-size: 1.2rem;
    padding: 8px;
  }

  .carousel-prev {
    left: 8px;
  }

  .carousel-next {
    right: 8px;
  }
}

@media (max-width: 768px) {
  .carousel-container {
    max-width: 600px;
  }

  .carousel-btn {
    font-size: 1rem;
    padding: 6px;
  }

  .carousel-prev {
    left: 6px;
  }

  .carousel-next {
    right: 6px;
  }
}

@media (max-width: 480px) {
  .carousel-container {
    max-width: 100%;
  }

  .carousel-btn {
    font-size: 0.8rem;
    padding: 4px;
  }

  .carousel-prev {
    left: 4px;
  }

  .carousel-next {
    right: 4px;
  }
}
