@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

:root {
  --color-white: #ffff;
  --color-barra: #ffdf1b;
  --color-dark: #000000;
  --color-btn: #fff885;
  --color-gris: rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.labelr {
  margin-right: 100%;
}

.recorrido {
  text-align: center;
  padding: 20px;
}

.titulor {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 35px;
}

.virtual-tour {
  margin: 20px 0;
}

.subtitulor {
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.tour-playing {
  margin-top: 20px;
}

.ku-embed {
  width: 220vh;
  height: 85vh;
  border: none;
  border-radius: 10px;
}

.comentario-form {
  border: none;
  padding: 1em;
  width: 75%;
  box-sizing: border-box;
  text-align: center;
  margin-left: 12.5%;
  background-color: var(--color-gris);
  border-radius: 15px;
  margin-top: 20px;
}

.comentario-form.form {
  max-width: 95%;
  margin: auto;
}

.comentario-form.form-group {
  margin-bottom: 15px;
}

.comentario-form.btn {
  width: auto;
}

.botonComentario {
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  border-radius: 10px;
  width: 25%;
  margin-left: 75%;
  border: none;
  border-color: var(--color-barra);
  padding: 5px;
  height: 35%;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .ku-embed {
    width: 180vh;
    height: 75vh;
  }

  .comentario-form {
    width: 85%;
    margin-left: 7.5%;
  }

  .botonComentario {
    width: 35%;
    margin-left: 65%;
  }
}

@media (max-width: 992px) {
  .ku-embed {
    width: 150vh;
    height: 65vh;
  }

  .comentario-form {
    width: 90%;
    margin-left: 5%;
  }

  .botonComentario {
    width: 45%;
    margin-left: 55%;
  }
}

@media (max-width: 768px) {
  .ku-embed {
    width: 100vh;
    height: 55vh;
  }

  .comentario-form {
    width: 95%;
    margin-left: 2.5%;
  }

  .botonComentario {
    width: 50%;
    margin-left: 50%;
  }
}

@media (max-width: 576px) {
  .ku-embed {
    width: 80vh;
    height: 45vh;
  }

  .comentario-form {
    width: 100%;
    margin-left: 0;
  }

  .botonComentario {
    width: 55%;
    margin-left: 45%;
  }
}

@media (max-width: 480px) {
  .ku-embed {
    width: 70vh;
    height: 40vh;
  }

  .comentario-form {
    width: 100%;
    margin-left: 0;
  }

  .botonComentario {
    width: 60%;
    margin-left: 40%;
  }
}

@media (max-width: 360px) {
  .ku-embed {
    width: 60vh;
    height: 35vh;
  }

  .botonComentario {
    width: 70%;
    margin-left: 30%;
  }
}
