
.linea {

    height: 15px;
    background-color: #99b3c6;
    border: none;
    width: 95%;
    /* Ajusta el ancho según lo necesites */
    margin: 15px;
    /* Esto centra horizontalmente la línea */
}

.datepicker {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
    font-family: 'Open Sans', sans-serif;
}

.btnVC{
    background-color: #ffdf1b; /* El color de fondo que prefieras */
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: black; /* El color del texto */
}

.btnVC:hover {
    background-color: #fff885; /* Color de fondo cuando se pasa el ratón por encima */
    border: #fff885;
}



/* lista */

.d-flex {
    display: flex;
}

.col-comment, .col-date, .col-actions {
    flex: 1;
}

.col-comment {
    flex: 2;
    overflow-wrap: break-word;
}

.col-date, .col-actions {
    flex: 1;
}

.col-comment, .col-date, .col-actions {
    padding: 0.5rem;
}

.person{
    font-size: 15px;
    font-weight: bold;
}


.custom-modal .modal-dialog {
    max-width: 60%; /*  ancho máximo del modal */
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
}

.custom-modal .modal-content {
    width: 100%; /* Asegura que el contenido del modal ocupe todo el ancho disponible */
    max-width: 100%; /* Ajusta el ancho máximo del modal */
    height: auto; /* Ajusta la altura automáticamente */
    max-height: 100vh; /* Ajusta la altura máxima del modal */
    margin: 0; /* Elimina márgenes */
}


.custom-dialog {
    max-width: 90vw; /* Ajusta el ancho máximo del modal según sea necesario */
    width: auto; /* Permite que el modal se ajuste al contenido */
}
.custom-modal .modal-body {
    padding: 0; /* Ajusta el padding si es necesario */
}

.table-responsive-lg {
    max-height: 60vh; /* Ajusta la altura máxima de la tabla */
    overflow-y: auto; /* Agrega desplazamiento vertical a la tabla */
}

.table-custom {
    width: 100%; /* Ocupa todo el ancho disponible del modal */
    min-width: 800px; /* Ajusta el ancho mínimo de la tabla si es necesario */
}


.n{
    background-color:#fff885;
    
}
.color2 {
    background-color: #ff9966;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
  }
  
  .color2:hover {
    background-color: #ffc8a9; 
    border: #ffc8a9;
  }