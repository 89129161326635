@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

:root {
  --color-white: #ffff;
  --color-barra: #ffdf1b;
  --color-dark: #000000;
  --color-btn: #fff885;
  --color-gris: rgba(0, 0, 0, 0.1);
}

/* 
! Aquí es navbar
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  background-color: var(--color-barra);
}

.navbar {
  background-color: #c0c0c0;
}

.btn-agenda,
.btn2 {
  border: 0px;
  font-weight: bold;
  border-radius: 25px;
  font-size: 15px;
}

.letra {
  color: #482200;
  font-size: 15px;
  font-family: 'Open Sans', 'sans-serif';
}

.btn-agenda:hover {
  background-color: var(--color-btn);
}

.btn2:hover {
  background-color: transparent;
}

/* 
! Estilos para el cuerpo y títulos
*/

.body {
  margin: 1rem;
}

.tituloa {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 35px;
}

/* 
* Estilos para las tarjetas de eventos
*/

.event-card {
  width: 18rem;
  margin: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.event-card:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.event-image {
  width: 100%;
  height: 10rem; /* Ajusta la altura según sea necesario */
  object-fit: contain; /* Ajusta la imagen sin recortarla, mantiene la proporción */
  margin: 5px; /* Agrega margen de 3px */
}

.event-content {
  padding: 1rem;
  flex: 1; /* Hace que el contenido se expanda para ocupar el espacio restante */
  text-align: justify;
}

.event-title {
  font-size: 1.5rem;
  margin: 0 0 0.5rem;
}

.event-description {
  font-size: 1rem;
  margin: 0 0 0.5rem;
  text-align: justify;
}

.event-date {
  font-size: 0.875rem;
  color: #555;
  margin: 0 0 1rem;
}

.event-button {
  padding: 0.5rem 1rem;
  color: var(--color-dark);
  background-color: var(--color-barra);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.event-button:hover {
  background-color: var(--color-btn);
}

/* 
! Estilos para los modales
*/

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.modal-close-button {
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  font-size: 1rem;
  text-align: justify;
}

/* 
! Estilos para el registro de agenda
*/

.App2 {
  display: flex;
  align-items: start;
  padding: 2rem;
}

.linea {
  height: 15px;
  background-color: #99b3c6;
  border: none;
  width: 95%; /* Ajusta el ancho según lo necesites */
  margin: 0 auto; /* Esto centra horizontalmente la línea */
}

label {
  font-size: 18px;
  font-weight: bold;
}

h1 {
  margin-bottom: 1rem;
}

.boton_agen {
  width: 50%;
  height: auto;
  font-size: 2vw;
  font-family: 'Open Sans', sans-serif;
  border-radius: 10px;
  font-weight: bold;
  border: none;
}

.boton_agen:hover {
  background-color: var(--color-btn);
}

.date-input-container .input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.input-group-append {
  position: absolute;
  right: 15px; /* Ajusta según sea necesario */
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.calendar-button {
  margin-left: 0.5rem;
  padding: .30rem 1rem; /* Ajusta el padding para que el botón sea más cuadrado */
  font-size: .1rem; /* Aumenta el tamaño de la fuente */
  cursor: pointer;
  background-color: var(--color-barra);
  color: var(--color-dark);
  border: none;
  border-radius: 4px;
}

.col-md-6 {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente (opcional) */
}

.agenda-image {
  width: 50%; /* Ajusta el tamaño según tus necesidades */
  height: 50%; /* Agrega margen de 3px */
}

.modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.date-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 1rem 0;
}

.date-button {
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.date-button.available {
  background-color: #ffcc00;
}

.date-button.available:hover {
  background-color: var(--color-btn);
}

.date-button.unavailable {
  background-color: #996600;
  color: white;
  cursor: not-allowed;
}

.modal-close-button {
  padding: 0.5rem 1rem;
  background-color: #ffcc00;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.time-grid {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.time-button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  background-color: var(--color-barra);
}

.time-button:hover {
  background-color: var(--color-btn);
}

.mod_agendar {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
}

.search {
  margin-left: 0.5rem;
  background-color: var(--color-barra);
  color: var(--color-dark);
  border: none;
  border-radius: 4px;
}

.flex-grow-1 {
  flex-grow: 1;
}

.me-2 {
  margin-right: 8px; /* Espacio entre el input y el botón */
}
