

@media (min-width: 600px) {
    .cuadroVirtual {
        width: 48%;
        /* Dos columnas en pantallas medianas */
    }
}

@media (min-width: 900px) {
    .cuadroVirtual {
        width: 30%;
        /* Tres columnas en pantallas grandes */
    }
}

.btnRecorrido{
    background-color: #ffdf1b; 
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    opacity: .90;
}

.btnRecorrido:hover {
    background-color: #fff885; 
    border: #fff885;
}

.color2 {
    background-color: #ff9966;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
  }
  
  .color2:hover {
    background-color: #ffc8a9; 
    border: #ffc8a9;
  }
.n{
    background-color:#fff885;
    
}

.carText{
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
   
}

.cardTitle{
    font-family: 'Open Sans', sans-serif;
    font-family: 18px;
    font-weight: bold;
}


.img-small {
    width: 50px; /* Ajusta el tamaño según sea necesario */
    height: auto; /* Mantiene la proporción */
}