.CuadroLugar {
    width: 460px;
    height: max-content;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.BotonL{
    margin-left: 800px;
}

.descripcionje{
    text-align: center;
    font-weight: lighter;
}
.btnEven{
    background-color: #ffdf1b; 
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    opacity: .90;
}

.btnEven:hover {
    background-color: #fff885; 
    border: #fff885;
}
.n{
    background-color:#fff885;
    
}